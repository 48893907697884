import { createParamsApi } from "../../api"
import { fetcher } from "@/utils/fetcher"
import { getManualSearchStatus } from "@/store/user/auth/actions"

export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDERS_FETCHING = 'SET_ORDERS_FETCHING'
export const SET_STARTED_ORDERS = 'SET_STARTED_ORDERS'
export const SET_ORDER_SELECTED_STATUS = 'SET_ORDER_SELECTED_STATUS'
export const SET_ORDER_SEARCH = 'SET_ORDER_SEARCH'
export const SET_COMPLETED_ORDER_ID = 'SET_COMPLETED_ORDER_ID'

export const getOrders = () => async (dispatch, getState) => {
  dispatch({ type: SET_ORDERS_FETCHING, status: true })
  const api = getApi()(dispatch, getState)
  let data = await fetcher(api)
  setOrders({ data })(dispatch, getState)
  dispatch({ type: SET_ORDERS_FETCHING, status: false })
}

export const setOrders = ({ data }) => (dispatch, getState) => {
  let { orders, started_count } = data
  orders = filteredForManualSearch({ orders })(dispatch, getState)
  dispatch({ type: SET_ORDERS, orders })
  dispatch({ type: SET_STARTED_ORDERS, startedCount: started_count })
}

export const filteredForManualSearch = ({ orders }) => (dispatch, getState) => {
  const isManualSearch = getManualSearchStatus()(dispatch, getState)
  if (isManualSearch) {
    const { search } = getState().order
    orders = orders.filter(item => search.length > 0 || item.status !== 'started' || item.is_left || item.is_refused || item.has_been_found)
  }
  return orders
}

export const setCompletedFilterId = ({ id }) => (dispatch, getState) => {
  dispatch({ type: SET_COMPLETED_ORDER_ID, id })
}

export const filterCompletedOrderById = ({ item }) => (dispatch, getState) => {

}

export const getApi = () => (dispatch, getState) => {
  const { selectedStatus, search } = getState().order

  const types = [ 'started', 'completed' ]

  let api = createParamsApi({ method: 'getOrders', section: 'order', errorMessage: 'getOrders' })
  api.params = {
    search: search
  }
  return api
}

export const changeSelectedStatus = item => async (dispatch, getState) => {
  dispatch({ type: SET_ORDER_SELECTED_STATUS, status: item.id })
}

export const changeSearch = value => (dispatch, getState) => {
  dispatch({ type: SET_ORDERS_FETCHING, status: true })
  dispatch({ type: SET_ORDER_SEARCH, value })
  setTimeout(() => {
    console.log(value, getState().order.search)
    if (value === getState().order.search) {
      getOrders()(dispatch, getState)
    }
  }, 300)
}

export const closeOrder = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDERS_FETCHING, status: true })
  /* istanbul ignore next */
  try {
    await fetcher(createParamsApi({
      section: 'order',
      errorMessage: 'closeOrder',
      method: 'closeOrder',
      apiUrlParams: { id }
    }))
  } catch (err) {

  }
  dispatch({ type: SET_ORDER_SEARCH, value: '' })
  await getOrders()(dispatch, getState)
}

export const refuseOrder = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDERS_FETCHING, status: true })
  /* istanbul ignore next */
  try {
    await fetcher(createParamsApi({
      section: 'order',
      errorMessage: 'refuseOrder',
      method: 'refuseOrder',
      apiUrlParams: { id }
    }))
  } catch (err) {
    console.log(err)
  }
  dispatch({ type: SET_ORDER_SEARCH, value: '' })
  await getOrders()(dispatch, getState)
}

export const exitCustomer = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDERS_FETCHING, status: true })
  await fetcher(createParamsApi({
    section: 'order',
    errorMessage: 'exitCustomer',
    method: 'exitCustomer',
    apiUrlParams: { id }
  }))
  await getOrders()(dispatch, getState)
}
